import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'white'
    },

    wrapper: {
        marginTop: '20%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      
    },
    text: {
        fontSize: '1.5em',
    }
}
const LoadingPage = (props) => {

    return (
        
        <div style={styles.root}>
            <div style={styles.wrapper}>
                <CircularProgress size={80} />
                <div style={styles.text}>{props.title || 'App wird geladen...'}</div>
            </div>
        </div>
    )

}

export default LoadingPage